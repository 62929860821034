export const initPopupHandler = () => {
    const popupButtons = document.querySelectorAll('.popup-btn');

    if (!popupButtons.length) return;

    const openPopup = (target) => {
        document.body.classList.add('lock');

        if (document.querySelector(`.popup[data-id=${target}]`)) {
            document.querySelector(`.popup[data-id=${target}]`).classList.add('active');
        }
    };

    const closePopup = (target) => {
        if (target.matches('.popup') || target.matches('.popup-window__close-btn')) {
            document.body.classList.remove('lock');
            target.closest('.popup').classList.remove('active');
        }
    };

    popupButtons.forEach((button) => {
        button.addEventListener('click', () => {
            openPopup(button.dataset.target);
        });
    });

    document.addEventListener('click', (evt) => {
        closePopup(evt.target);
    });

    document.addEventListener('keyup', (evt) => {
        if (evt.key === 'Escape') {
            if (document.querySelector('.popup.active')) {
                document.body.classList.remove('lock');
                document.querySelector('.popup.active').classList.remove('active');
            }
        }
    });
};
