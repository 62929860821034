export const initFormFile = () => {
    const formFiles = document.querySelectorAll('.form-file input[type=file]');

    if (!formFiles.length) return;

    formFiles.forEach((input) => {
        input.addEventListener('change', () => {
            if (input.files[0].size > 8388608) {
                console.log('File is too big!');
                input.value = '';
                return;
            }
            let file = input.files[0];
            input.closest('.form-file').querySelector('.form-file-text').textContent = file.name;
        });
    });
};
