import { SLIDERS_CONFIG } from "./sliders-config.js";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";

(() => {
  const sliders = document.querySelectorAll("[data-slider]");

  if (!sliders.length) return;

  const initSlider = (slider) => {
    const type = slider.dataset["slider"];
    const config = SLIDERS_CONFIG[type];

    new Swiper(slider, config);
  };

  sliders.forEach((slider) => initSlider(slider));

  // if (document.querySelector(".history__slider")) {
  //   let history = new Swiper(".history__slider", {
  //     direction: "horizontal",
  //     loop: false,
  //     slidesPerView: 1,
  //     spaceBetween: 30,
  //     modules: [Mousewheel],
  //     mousewheel: true,

  //     breakpoints: {
  //       480: {
  //         slidesPerView: 1,
  //         spaceBetween: 30,
  //       },
  //       768: {
  //         slidesPerView: 1,
  //         spaceBetween: 40,
  //       },
  //       1280: {
  //         slidesPerView: 2,
  //         spaceBetween: 100,
  //       },
  //       1440: {
  //         slidesPerView: 2,
  //         spaceBetween: 100,
  //       },
  //       1600: {
  //         slidesPerView: 2,
  //         spaceBetween: 100,
  //       },
  //       1920: {
  //         slidesPerView: 2,
  //         spaceBetween: 150,
  //       },
  //     },
  //   });

  //   history.on("slideNextTransitionEnd", function (e) {
  //     if (e.isEnd) {
  //       e.mousewheel.disable();
  //     }
  //   });
  // }

  if (document.querySelector(".press-center__slider")) {
    let pressCenter = new Swiper(".press-center__slider", {
      direction: "horizontal",
      loop: false,
      slidesPerView: 1.3,
      spaceBetween: 0,
      modules: [Navigation],

      navigation: {
        nextEl: ".press-center__nav .slider-button-next_thin",
        prevEl: ".press-center__nav .slider-button-prev_thin",
      },

      breakpoints: {
        480: {
          slidesPerView: 2.1,
        },
        768: {
          slidesPerView: 3.3,
        },
        2560: {
          slidesPerView: 4.3,
        }
      },
    });
  }

  if (document.querySelector(".other-directions__slider")) {
    let otherDirections = new Swiper(".other-directions__slider", {
      direction: "horizontal",
      loop: false,
      slidesPerView: 1.3,
      spaceBetween: 16,
      watchOverflow: true,
      modules: [Navigation],

      navigation: {
        nextEl: ".other-directions__nav .slider-button-next_thin",
        prevEl: ".other-directions__nav .slider-button-prev_thin",
      },

      breakpoints: {
        480: {
          slidesPerView: 2.1,
        },
        768: {
          slidesPerView: 2.5,
        },
        1024: {
          slidesPerView: 3.5,
        },
        1280: {
          slidesPerView: 4.1,
          spaceBetween: 20,
        },
      },
    });
  }
})();
