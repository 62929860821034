import "./modules/jquery-3.7.1.min.js";

import { initFormFile } from "./modules/form-file.js";
import { initPopupHandler } from "./modules/popup.js";
import { initHeader } from "./modules/header.js";
import { initProjects } from "./modules/projects.js";
import { initTextAreas } from "./modules/textarea.js";

import "./components/sliders.js";
import "./components/inputs.js";
import "./components/mask.js";

document.addEventListener("DOMContentLoaded", () => {
    initFormFile();
    initHeader();
    initProjects();
    initPopupHandler();
    initTextAreas();
});
