import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export const SLIDERS_CONFIG = {
    main: {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1000,
        modules: [Navigation, Pagination, Autoplay],
        autoplay: {
            delay: 5500,
        },

        pagination: {
            el: '.main-section__container .swiper-pagination',
            type: 'fraction',

            formatFractionCurrent: function (number) {
                return ('0' + number).slice(-2);
            },

            formatFractionTotal: function (number) {
                return ('0' + number).slice(-2);
            },

            renderFraction: function (currentClass, totalClass) {
                return `<span class="${currentClass}"></span> <span class="fract-total"> / <span class="${totalClass}"></span></span>`;
            },
        },

        navigation: {
            nextEl: '.main-section__container .slider-button-next',
            prevEl: '.main-section__container .slider-button-prev',
        },
    },
    projectsSlider1: {
        direction: 'horizontal',
        slidesPerView: 2.2,
        spaceBetween: 0,
        speed: 3800,
        loop: true,
        allowTouchMove: false,
        modules: [Autoplay],
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        on: {
            init: function () {
                if (window.matchMedia('(max-width: 1279px)').matches) {
                    document.querySelector('.projects__carousel').style.opacity = 1;
                }
            },
        },
        breakpoints: {
            480: {
                slidesPerView: 3.2,
            },
            768: {
                slidesPerView: 3.2,
                speed: 3500,
            },
            1024: {
                slidesPerView: 4.2,
            },
            1280: {
                direction: 'vertical',
                slidesPerView: 2.9,
                speed: 8800,
            },
        },
    },
    projectsSlider2: {
        direction: 'vertical',
        slidesPerView: 2.9,
        spaceBetween: 0,
        loop: true,
        allowTouchMove: false,
        modules: [Autoplay],
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: true,
        },
        speed: 8800,
    },
    projectsSlider3: {
        direction: 'vertical',
        slidesPerView: 2.9,
        spaceBetween: 0,
        loop: true,
        allowTouchMove: false,
        modules: [Autoplay],
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        speed: 8800,
        on: {
            init: function () {
                if (window.matchMedia('(min-width: 1280px)').matches) {
                    document.querySelector('.projects__carousel').style.opacity = 1;
                }
            },
        },
    },

    card: {
        direction: 'horizontal',
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        watchOverflow: true,
        modules: [Navigation],

        navigation: {
            nextEl: '.swiper .card-button-next',
            prevEl: '.swiper .card-button-prev',
        },
    },
};
