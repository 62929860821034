export const initProjects = () => {
    if (document.querySelector(".projects-section")) {
        const projectViewButtons = document.querySelectorAll(
            ".projects-section__nav-btn"
        );
        if (!projectViewButtons) return;

        const projectsSectionWrapper = document.querySelector(
            ".projects-section__wrapper"
        );

        const projectsViewHandler = (evt) => {
            projectsSectionWrapper.style.opacity = 0;
            document
                .querySelector(".projects-section__nav-btn.active")
                .classList.remove("active");

            setTimeout(() => {
                if (
                    evt.target.classList.contains(
                        "projects-section__nav-btn_grid"
                    )
                ) {
                    projectsSectionWrapper.classList.remove("list");
                    projectsSectionWrapper.classList.add("grid");
                    document
                        .querySelector(".projects-section__show-all")
                        .removeAttribute("style");
                    evt.target.classList.add("active");
                } else if (
                    evt.target.classList.contains(
                        "projects-section__nav-btn_list"
                    ) ||
                    evt.target.classList.contains("projects-section__show-all")
                ) {
                    projectsSectionWrapper.classList.remove("grid");
                    projectsSectionWrapper.classList.add("list");
                    document
                        .querySelector(".projects-section__nav-btn_list")
                        .classList.add("active");
                    document.querySelector(
                        ".projects-section__show-all"
                    ).style.display = "none";
                }
            }, 200);

            setTimeout(() => (projectsSectionWrapper.style.opacity = 1), 300);
        };

        projectViewButtons.forEach((button) => {
            button.addEventListener("click", (evt) => projectsViewHandler(evt));
        });

        document
            .querySelector(".projects-section__show-all")
            .addEventListener("click", () =>
                document
                    .querySelector(".projects-section__nav-btn_list")
                    .click()
            );
    }
};
