export const initHeader = () => {
    const header = document.querySelector('.header');
    if (!header) return;

    const openMenuButton = header.querySelector('#openMenu');
    const closeMenuButton = header.querySelector('#closeMenu');
    const headerMenu = header.querySelector('.header__menu');

    const openMenu = () => {
        headerMenu.classList.add('shown');
        document.body.classList.add('menu-shown');
    };

    const closeMenu = () => {
        headerMenu.classList.remove('shown');
        document.body.classList.remove('menu-shown');
    };

    openMenuButton.addEventListener('click', openMenu);
    closeMenuButton.addEventListener('click', closeMenu);
    document.addEventListener('keyup', (evt) => {
        if (evt.key === 'Escape') {
            closeMenu();
            if (document.querySelector('.search-wrapper.active')) {
                document.querySelector('.search-wrapper.active').classList.remove('active');
            }
        }
    });
};
