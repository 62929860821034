(() => {
    const inputs = document.querySelectorAll('.form-field input:not(input[type="file"]), .form-field textarea');
    if (!inputs.length) return;

    const initInput = (input) => {
        const parent = input.closest('.form-field');
        input.addEventListener('blur', () => {
            if (input.value.length > 0) {
                parent.classList.add('active')
            } else {
                parent.classList.remove('active');
            }
        })
    }

    inputs.forEach(input => initInput(input));
})()
