export const initTextAreas = () => {
    const textAreas = document.querySelectorAll('.form-field textarea');

    if (!textAreas.length) return;
    textAreas.forEach((textarea) => {
        textarea.setAttribute('style', 'height:' + textarea.scrollHeight + 'px;overflow-y:hidden;');
        textarea.addEventListener('input', function (evt) {
            evt.target.style.height = 0;
            evt.target.style.height = evt.target.scrollHeight + 'px';
        });
    });
};
